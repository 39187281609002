import Head from "next/head";
import React, { useState, useContext } from "react";
import { Button, Skeleton, Alert } from "antd";
import styled from "styled-components";
import { queryCache } from "react-query";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";

import { LoginStateContext } from "../context/Login/LoginState";
import { apiAxios } from "../services/HttpServices";
import { useFetchLocalStorage } from "../lib/useFetchLocalStorage";
import useUser from "../lib/useUser";
import Input from "../components/form/Input";

const Login = () => {
  const [error, setError] = useState("");
  const { user, loginUser, loadingUser, finishLoadingUser } = useContext(
    LoginStateContext
  );
  const { handleSubmit, control, errors, reset } = useForm({
    mode: "onChange",
  });

  useFetchLocalStorage();
  const { status } = useUser({
    redirectTo: "/",
    redirectIfFound: true,
  });

  const onSubmit = async (data) => {
    setError("");
    loadingUser();
    try {
      const res = await apiAxios.post("/auth/login", data);
      finishLoadingUser();
      loginUser(res.data.token);
      localStorage.setItem("token", res.data.token);
      // queryCache.refetchQueries("current-user");
    } catch (error) {
      reset(data);
      setError(error.response.data.message);
      finishLoadingUser();
    }
  };
  return (
    <>
      <Head>
        <title>Login - Tihani</title>
      </Head>
      <StyledContainer>
        <StyledLoginContainer>
          <StyledHeaderContainer>
            <h1>Tihani Management System</h1>
          </StyledHeaderContainer>
          {error && (
            <Alert
              message="Error"
              description={error}
              type="error"
              showIcon
              style={{ marginBottom: "10px" }}
            />
          )}
          {status === "loading" || user.loading || user.isLoggedIn ? (
            <Skeleton input active />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Input
                errors={errors}
                name="email"
                placeholder="Email"
                size="large"
                propsInput={{
                  control: control,
                  defaultValue: "",
                }}
              />
              <Input
                errors={errors}
                name="password"
                placeholder="Password"
                size="large"
                propsInput={{
                  control: control,
                  defaultValue: "",
                }}
              />

              <Button
                block
                type="primary"
                size="large"
                htmlType="submit"
                loading={user.loading}
              >
                Sign In
              </Button>
            </form>
          )}
        </StyledLoginContainer>
      </StyledContainer>
      <DevTool control={control} />
    </>
  );
};

const StyledContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-size: cover;
  background-image: url("/images/signin-bg.jpg");
`;

const StyledLoginContainer = styled.div`
  width: 500px;
  height: 100vh;
  background: ${(props) => props.theme.colors.grey[200]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 56px 40px;
`;

const StyledHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

export const getLayout = (page) => <>{page}</>;
Login.getLayout = getLayout;

export default Login;
