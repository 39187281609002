import styled from "styled-components";
import { Controller } from "react-hook-form";
import { Input as InputAnt } from "antd";

const StyledContainer = styled.div`
  .styled-input {
    margin-bottom: 10px;
  }
`;

const Input = (props) => {
  const UseThisInput = props.name === "password" ? InputAnt.Password : InputAnt;
  return (
    <StyledContainer>
      {props.label && <label htmlFor={props.name}>{props.label}</label>}
      <Controller
        as={
          <UseThisInput
            size={props.size}
            placeholder={props.placeholder}
            className="styled-input"
          />
        }
        name={props.name}
        {...props.propsInput}
      />

      {props.errors && props.errors[props.name] && (
        <span style={{ color: "#f00" }}>
          {props.errors && props.errors[props.name].message}
        </span>
      )}
    </StyledContainer>
  );
};
export default Input;
